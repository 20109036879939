<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <component
    :is="component"
    :href="url"
    class="article-module1 d-block"
  >
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover && hasHover ? 0 : 0"
        :class="{
          'on-hover': hover && hasHover,
        }"
      >
        <div class="d-flex flex-md-row flex-column justify-start align-center">
          <v-img
            v-if="photoUrl"
            cover
            :width="photoWidth"
            :aspect-ratio="1 / 1"
            :src="photoUrl"
            :alt="photoName"
            class="article-module1_img"
          >
          </v-img>
          <div
            class="article-module1_text d-flex flex-column justify-center"
            :class="{ 'pl-0': !photoUrl }"
          >
            <!-- 雲端講堂內容頁 -->
            <div v-if="titleType == 'between'" class="text-center text-md-left">
              <!-- <h2 class="article-module1_text_title iCloudTitle  mb-3"> 
                {{ mergeTitle }}
              </h2> -->
              <h2
                class="
                  article-module1_text_title article-module1_text_title--icloud
                  text-overflow-ep-2 text-overflow-ep-lg-1
                  mb-3
                "
              >
                {{ title }}
              </h2>
              <h3
                class="
                  article-module1_text_subtitle
                  article-module1_text_subtitle--icloud
                  text-overflow-ep-2 text-overflow-ep-lg-1
                  mb-3
                "
              >
                {{ subtitle }}
              </h3>
            </div>
            <!-- 動態頁面 -->
            <template v-else>
              <h2
                class="
                  article-module1_text_title
                  text-overflow-ep-2 text-overflow-ep-lg-1
                  mb-3
                "
              >
                {{ title }}
              </h2>
              <h3
                class="
                  article-module1_text_subtitle
                  text-overflow-ep-2 text-overflow-ep-lg-1
                  mb-3
                "
              >
                {{ subtitle }}
              </h3>
            </template>

            <p
              v-if="content"
              v-html="nl2br(content)"
              :class="contentClass"
              class="article-module1_text_content text-overflow-ep-4"
            ></p>
          </div>
        </div>
      </v-card>
    </v-hover>
  </component>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import itemMixins from "./item.js";
export default {
  mixins: [itemMixins],
  props: {
    hasHover: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isCloud() {
      const { name } = this.$route;
      return ["cloud-detail", "cloud-preview"].includes(name);
    },
    contentClass() {
      if (this.isCloud) return "iCloudContent";
      return "DynamicContent";
    },
    titleType() {
      if (this.isCloud) return "between";
      return "";
    },
    mergeTitle() {
      if (this.titleType != "between") return;
      return `${this.title} ${this.subtitle}`;
    },
    component() {
      if (this.url) return "a";
      return "div";
    },
    url() {
      if (!this.item) return "";
      return this.$helper.modifyUrl(this.item.url, this);
    },
    windowWidth() {
      return this.$store.getters["base/windowWidth"];
    },
    photoWidth() {
      if (this.isCloud) {
        if (this.windowWidth < this.$vuetify.breakpoint.thresholds.xs)
          return 200;
        if (this.windowWidth < this.$vuetify.breakpoint.thresholds.md)
          return 200;
        return 200;
      }
      if (this.windowWidth < this.$vuetify.breakpoint.thresholds.xs)
        return "100%";
      if (this.windowWidth < this.$vuetify.breakpoint.thresholds.md) return 250;
      return 250;
    },
  },
};
</script>